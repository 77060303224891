@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url(./assets/fonts/Cairo-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Cairo-bold";
  src: local("Cairo"), url(./assets/fonts/Cairo-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Cairo-semi";
  src: local("Cairo"), url(./assets/fonts/Cairo-SemiBold.ttf) format("truetype");
}

:root {
  --primary-color: #8c0000;
  --dark-color: #001427;
  --darker-color: #3e3e3e;
  --light-color: #fff9f7;
  --danger-color: #ffe923;
  --success-color: #28a745;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Cairo", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  /* background-color: #fff; */
  color: var(--light-color);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  direction: rtl;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cairo-Bold", sans-serif;
}
/*GLOBALS*/
a {
  text-decoration: none;
}

/*texts*/
.cairo {
  font-family: "Cairo" !important;
}
.center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-primary {
  color: var(--primary-color);
}
.text-light {
  color: var(--light-color);
}
.text-error {
  color: var(--error-color);
}
.text-secondary {
  color: var(--secondary-color);
}
.text-success {
  color: var(--success-color);
}
.text-shadow-grey {
  text-shadow: -1px 1px 1px var(--dark-color);
}
.text-shadow-light {
  text-shadow: -1px 1px 1px var(--light-color);
}
.text-larger {
  font-size: larger;
}
.text-xlarge {
  font-size: x-large;
}
.text-xxlarge {
  font-size: xx-large;
}
.bold {
  font-weight: bold;
}
.underlined {
  text-decoration: underline;
  text-underline-position: under;
}
/* Padding */

.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 0 3rem;
}

.px {
  padding: 0 0.5rem;
}

.px-1 {
  padding: 0 1rem;
}

.px-2 {
  padding: 0 2rem;
}

.px-3 {
  padding: 0 3rem;
}
.clearp {
  padding: 0;
}
/* Margin */

.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mx {
  margin: 0 0.5rem;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 2rem;
}
.mx-3 {
  margin: 0 3rem;
}
.clearm {
  margin: 0;
}
.clearp {
  padding: 0;
}
.fullheight {
  height: 100%;
}
.fullheightVH {
  height: 100vh;
}
.fullwidth {
  width: 100%;
}
.flex__centerlize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.block {
  display: block;
}
.flex {
  display: flex;
  /* margin: auto; */
}
.center-self {
  align-self: center;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.ver {
  flex-direction: column;
}
.hor {
  flex-direction: row;
  flex-flow: wrap;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.grow1 {
  flex-grow: 1;
}

.bg-primary-gradient {
  background: rgb(154, 49, 49);
  background: -moz-radial-gradient(
    circle,
    rgba(154, 49, 49, 1) 0%,
    rgba(140, 0, 0, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(154, 49, 49, 1) 0%,
    rgba(140, 0, 0, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(154, 49, 49, 1) 0%,
    rgba(140, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9a3131",endColorstr="#8c0000",GradientType=1);
}
